/*
 * MEPP - A web application to guide patients and clinicians in the process of
 * facial palsy rehabilitation, with the help of the mirror effect and principles
 * of motor learning
 * Copyright (C) 2021 MEPP <info@mirroreffectplus.org>
 *
 * This file is part of MEPP.
 *
 * MEPP is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * MEPP is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with MEPP.  If not, see <http://www.gnu.org/licenses/>.
 */
import React from 'react';

import { Typography as MuiTypography } from '@mui/material';

import { sanitizeRestProps } from '@admin/utils/props';

export const Div = (props) => {
  const validProps = ['children', 'className'];
  const sanitizedProps = sanitizeRestProps(props, validProps, false);
  return <div {...sanitizedProps}>{props.children}</div>;
};

export const Typography = ({ gutterTop, ...props }) => {
  const validProps = [
    'children',
    'align',
    'color',
    'display',
    'gutterBottom',
    'noWrap',
    'paragraph',
    'variant',
    'variantMapping',
    'sx',
  ];
  const sanitizedProps = sanitizeRestProps(props, validProps, false);
  if (gutterTop) {
    sanitizedProps['sx'] = {
      ...(sanitizedProps?.sx || {}),
      marginTop: '10px',
    };
  }
  return <MuiTypography {...sanitizedProps}>{props.children}</MuiTypography>;
};
