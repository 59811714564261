/*
 * MEPP - A web application to guide patients and clinicians in the process of
 * facial palsy rehabilitation, with the help of the mirror effect and principles
 * of motor learning
 * Copyright (C) 2021 MEPP <info@mirroreffectplus.org>
 *
 * This file is part of MEPP.
 *
 * MEPP is free software: you can redistribute it and/or modify
 * it under the terms of the GNU General Public License as published by
 * the Free Software Foundation, either version 3 of the License, or
 * (at your option) any later version.
 *
 * MEPP is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
 * GNU General Public License for more details.
 *
 * You should have received a copy of the GNU General Public License
 * along with MEPP.  If not, see <http://www.gnu.org/licenses/>.
 */

export default {
  intro: {
    title: 'Hello {{name}}',
    introduction:
      'Ready to start your workout session? Focus on the muscle contractions and try to feel them, even if you don’t see anything moving. Keep the rest of your face relaxed.',
    instruction: 'Do the exercises slowly following the proposed pace.',
  },
  permission: {
    title: 'Permission',
    introduction:
      'To be able to continue your session, you need to allow this web page to access your camera and microphone.',
    instruction:
      'To access your session, you will first need to allow it by changing your browser settings.',
  },
};
